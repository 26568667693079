import {Screenshot} from "../stories/screenshot/screenshot";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";


export const Preview = ({titleText,maxWidth,$status,imageData}) => {

    return (
        <div>
            <ColumnTitle>{titleText}</ColumnTitle>
            <Screenshot maxWidth={maxWidth} $status={$status} imageData={imageData}/>
        </div>
    )
}

const ColumnTitle = styled.h4`
  margin-bottom: 30px;
`

Preview.propTypes = {
    /**
     * The title of the preview
     */
    titleText: PropTypes.string,
    /**
     * The maximum width of the image container
     */
    maxWidth: PropTypes.number,
    /**
     * The status of the source page
     */
    status: PropTypes.string,
    /**
     * The image data to display
     */
    imageData: PropTypes.string
}
