import React from "react";
import {SimpleVersion} from "../stories/version/simple/simple";
import styled from "styled-components";

const StyledDiv = styled.div`
    margin-top: auto;
    padding: 10px;
`;

export const Version = ({version}) => {
    return (
        <StyledDiv>
            <SimpleVersion version={version}/>
        </StyledDiv>
    )
}
