import React from 'react';
import PropTypes from 'prop-types';
import './loadmask.css';

export const Loadmask = ({loading,...props}) => {
    let loadMaskClass;
    if (loading === true){
        loadMaskClass = 'loading-mask'
    }
    return (
        <div style={props.style} className={loadMaskClass} >
          {props.children}
        </div>

    )
}

Loadmask.propTypes = {
//    /**
//     * This is an example description that is used for the storybook docs to describe the prop
//     */
//     myField: PropTypes.string,
}
Loadmask.defaultProps = {
    loading: PropTypes.bool
}