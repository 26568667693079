import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const StyledUlItem = styled.li`
  margin-bottom: 1rem;
  color: ${props => {
    return props.theme.colors.list.summary[props.type].text
  }};
  font-weight: ${props => {
    return props.theme.colors.list.summary[props.type].fontWeight
  }};
  text-align: left;
`

const StyledDiv = styled.div`
  text-align: left;
    margin-top: 30px
`
export const SummaryList = ({text, type, items, ...props}) => {

    return (
        <div>
            <StyledDiv>{text}</StyledDiv>
            <ul>
                {items.map(item =>
                    <StyledUlItem key={item} type={type}>{item}</StyledUlItem>
                )}
            </ul>
        </div>
    )
}

SummaryList.propTypes = {
    /**
     * The text to display at the top of the list
     */
    text: PropTypes.string,
    /**
     *  The type of summary list
     */
    type: PropTypes.oneOf(['default', 'reputation', 'risks']),
    /**
     *  The list of items to show
     */
    items: PropTypes.array
}

SummaryList.defaultProps = {
    text: '',
    items: [],
    type: 'default'
};