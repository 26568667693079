import {ThemeProvider} from "styled-components";
import {Bitdefender} from "./themes/bitdefender/Bitdefender.theme";
import {Base} from "./themes/base/Base.theme"
import {GlobalStyles} from './themes/GlobalStyles';

import {Scan} from "./components/Scan";
import {Reveal} from "./components/Reveal"
import {Version} from "./components/Version";
import styled from "styled-components";

const bdDomains = [
    'lsems.gravityzone.bitdefender.com',
    'lsems-dev.linkscan.io',
    'lsems-local'
]

const version = process.env.REACT_APP_VERSION

let activeTheme;
if (bdDomains.includes(window.location.hostname)) {
    activeTheme = Bitdefender
} else {
    activeTheme = Base
}
const url = new URL(window.location.href)
const params = url.searchParams

let paths = url.pathname.split('/')
let base64,
    hmac
if (paths[1] === 'scan') {
    if (paths[2] === 'ux') {
        base64 = paths[3]
        hmac = paths[4]
    } else {
        base64 = paths[2]
        hmac = paths[3]
    }
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;


function App() {
    return (
        <ThemeProvider theme={activeTheme}>
          <Container>
            <GlobalStyles/>
            {paths[1] === 'scan' &&
                <Scan hmac={hmac} base64={base64} params={params}/>
            }
            {paths[1] === 'reveal' &&
                <Reveal/>
            }
            <Version version={version}/>
          </Container>
        </ThemeProvider>
    );
}

export default App;
