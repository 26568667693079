// Note:  These values are in additional to the ones set in GlobalStyles.js

import {Base} from "../base/Base.theme"

const overrides = {
    id: 'T_002',
    name: 'Bitdefender',
    baseColors: {
        green: '#268E6C',
        white: '#fff',
        red: 'crimson',
        black: '#222'
    },
    body: {
        backgroundColor: '#B2B2B2',
        text: '#222',
        font: 'Montserrat'
    }
}


export const Bitdefender = () => {
    return Base(overrides)
}