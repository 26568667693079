import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCopy} from '@fortawesome/free-solid-svg-icons';

const StyledDiv = styled.div`
  text-align: left;
`
const StyledOl = styled.ol`
  font-size: 11px;
`
const StyledOlItem = styled.li`
  margin-bottom: 1rem;
  font-family: monospace;
  text-align: left;
  word-break: break-all;
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  cursor: pointer;
`;

const StyledExpand = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.colors.link.text};
  margin-left: 10px;
`

const StyledCopyText = styled.span`
  margin-left: 5px;
  color: ${props => props.theme.colors.base.green};
  font-weight: bold;
`

const truncate = (fullStr, strLen) => {
    if (fullStr.length <= strLen) return fullStr;

    let separator = "[...]";

    let sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars);
};

export const UrlList = ({text, expandable, expanded, urls, urlLength, ...props}) => {
    text = text.replace('{count}', urls.length)
    if (expandable === false) {
        expanded = true;
    }
    const [isExpanded, setIsExpanded] = React.useState(expanded)
    const onShowHideClick = () => setIsExpanded(!isExpanded)

    const urlState = urls.reduce(function (r, url) {
        r[url] = false;
        return r;
    }, {});

    const [isCopied, setIsCopied] = React.useState([urlState])

    const onCopyClick = (url) => {
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        let arr = [...isCopied]
        arr[url] = true
        setIsCopied(arr)
        setTimeout(function () {
            let arr = [...isCopied]
            arr[url] = false
            setIsCopied(arr)
        }, 2000);
    }
    return (
        <StyledDiv>
            <span>{text}</span>
            {expandable === true &&
            <StyledExpand onClick={onShowHideClick}>{isExpanded === true ? '<< Hide' : 'Show >>'}</StyledExpand>
            }
            {isExpanded === true &&
            <StyledOl>
                {urls.map(url =>
                    <StyledOlItem key={url}>{truncate(url, urlLength)}

                        <StyledIcon onClick={() => {
                            onCopyClick(url)
                        }}
                                    icon={faCopy}
                                    className="icon"/>
                        {isCopied[url] === true &&
                        <StyledCopyText>copied</StyledCopyText>
                        }
                    </StyledOlItem>
                )}
            </StyledOl>
            }
        </StyledDiv>
    );
};

UrlList.propTypes = {
    /**
     *  The text to display at the top of the list.  Include "{count}" if you want to show the number of urls in the list
     */
    text: PropTypes.string,
    /**
     * Is the list expandable?
     */
    expandable: PropTypes.bool,
    /**
     * Should the list start expanded.  Only applies if expandable is true
     */
    expanded: PropTypes.bool,
    /**
     *  The URLs to list
     */
    urls: PropTypes.array,
    /**
     * The length to truncate urls to
     */
    urlLength: PropTypes.number

};

UrlList.defaultProps = {
    expandable: true,
    expanded: false,
    urls: [],
    urlLength: 100
};
