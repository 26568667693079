import React from "react";
import styled from "styled-components";

import {Button} from "../stories/buttons/button";
import {Loadmask} from "../stories/overlay/loadmask/loadmask";
import axios from "axios";

export class Reveal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {url: '', revealUrl: null, errorMessage: null, isLoading: false};

        this.handleChange = this.handleChange.bind(this);
        this.revealUrl = this.revealUrl.bind(this);
        this.resetUrl = this.resetUrl.bind(this);
    }

    handleChange(event) {
        this.setState({url: event.target.value});
    }

    resetUrl() {
        this.setState({url: ''})
    }

    revealUrl() {
        this.setState({errorMessage: null, isLoading: true})
        let url;
        try {
            url = new URL(this.state.url)
        } catch (error) {
            url = null;
        }
        if (url) {
            const params = url.searchParams
            let paths = url.pathname.split('/')
            let path;
            if (paths[1] === 'scan') {
                if (paths[2] === 'ux') {
                    path = paths[3] + '/' + paths[4]
                } else {
                    path = paths[2] + '/' + paths[3]
                }
            }
            let uuid = params.get('uuid') ? '?uuid=' + params.get('uuid') : ''

            axios.get(`${process.env.REACT_APP_API_URL}/reveal/${path}${uuid}`)
                .then(
                    (response) => {
                        if (response.data.hasOwnProperty('errorType')) {
                            this.setState({errorMessage: response.data.errorMessage, isLoading: false})
                        } else {
                            this.setState({revealUrl: response.data, isLoading: false})
                        }
                    },
                    (error) => {
                        this.setState({errorMessage: error.message, isLoading: false})
                    }
                )
        } else {
            this.setState({errorMessage: 'Invalid URL', isLoading: false})
        }
    }

    render() {
        return (
            <Container>
                <Title>Reveal Link</Title>
                <Text>Paste a Link Scan URL below to reveal the original link.</Text>
                {this.state.errorMessage != null &&
                <ErrorText>{this.state.errorMessage}</ErrorText>
                }
                <Loadmask loading={this.state.isLoading} style={{maxWidth: '905px',marginLeft: 'auto',marginRight:'auto'}}>
                    <TextArea value={this.state.url} onChange={this.handleChange}/>
                    <Spacer/>
                    {this.state.revealUrl === null &&
                    <Button onClick={this.revealUrl} label={'Reveal'}/>
                    }
                    <Button style={{marginLeft: '10px'}} onClick={this.resetUrl} label={'Reset'}/>
                </Loadmask>
                {this.state.revealUrl !== null &&
                <div><Title>Original Url</Title>
                    <RevealedUrl>{this.state.revealUrl}</RevealedUrl>
                </div>}
            </Container>
        )
    }
}


const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`

const Title = styled.h2`
  margin-bottom: 30px;
`

const Text = styled.p`
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
`

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  max-width: 900px;

`

const Spacer = styled.div`
  height: 20px`


const RevealedUrl = styled.div`
  max-width: 900px;
  text-align: left;
  word-break: break-all;
  display: inline-block;
`
const ErrorText = styled.div`
  margin-bottom: 20px;
  color: ${props => props.theme.colors.base.red};
  font-weight: bold;
`