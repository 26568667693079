import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components"


const StyledVee = styled.span`
  font-family: monospace;
  font-size: 10px;
`

const StyledVersion = styled.span`
  font-family: monospace;
  font-size: 12px;    
`
export const SimpleVersion = ({version, ...props}) => {
    return (
        <div>
            <StyledVee>v</StyledVee><StyledVersion>{version}</StyledVersion>
        </div>
    )
}

SimpleVersion.propTypes = {
    version: PropTypes.string
}
SimpleVersion.defaultProps = {
    version: '1.0.0'
}