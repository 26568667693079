// These values apply to all themes

import { createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body ? theme.colors.body : '#fff'};
    margin: 0px;
    color: ${({ theme }) => theme.colors.text ? theme.colors.text : '#000'};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }

  a {
    color: ${({ theme }) => theme.colors.link.text};
    cursor: pointer;
  }

  button {
    font-size: 14px;
    padding: 11px 20px;
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    font-family: ${({ theme }) => theme.font};
  }

  button.btn {
    background-color: ${({ theme }) => theme.colors.button.background};
    color: ${({ theme }) => theme.colors.button.text};
  }
`;